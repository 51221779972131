<script setup>
import { usePage } from "@inertiajs/vue3";
import { getActiveLanguage } from "laravel-vue-i18n";
import { computed } from "vue";
import { getCorrectedCountryCode } from "@/util";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const currentLocale = getActiveLanguage();
const page = usePage();

function replaceLocaleInCurrentLink(oldLocale, newLocale) {
  // Landing page case(/en)
  if (window.location.pathname === `/${oldLocale}`) {
    return window.location.origin + `/${newLocale}` + window.location.search
  }

  const localeRegex = new RegExp(`/${oldLocale}/`, "i");
  const linkHasLocale = !!window.location.pathname.match(localeRegex);
  const newPath = linkHasLocale
    ? window.location.pathname.replace(localeRegex, `/${newLocale}/`)
    : `/${newLocale}/${window.location.pathname}`;
  return window.location.origin + newPath + window.location.search;
}

const localeOptions = computed(() =>
  Object.keys(page.props.localeList)
    .filter(
      (localeKey) => localeKey.toLowerCase() !== currentLocale.toLowerCase(),
    )
    .map((localeKey) => ({
      label: page.props.localeList[localeKey].native,
      code: getCorrectedCountryCode(localeKey),
      href: replaceLocaleInCurrentLink(currentLocale, localeKey),
    })),
);
</script>

<template>
  <q-btn flat class="q-pa-none">
    <q-item dense>
      <q-item-section side>
        <span
          :class="`fi fi-${getCorrectedCountryCode(currentLocale)}`"
        ></span>
      </q-item-section>
      <q-item-section>
        {{ currentLocale }}
      </q-item-section>
    </q-item>
    <q-menu auto-close>
      <q-item
        v-for="(opt, idx) in localeOptions"
        :key="idx"
        :href="opt.href"
        class="text-capitalize"
      >
        <q-item-section side>
          <span :class="`fi fi-${opt.code}`"></span>
        </q-item-section>
        <q-item-section>
          {{ opt.label }}
        </q-item-section>
      </q-item>
    </q-menu>
  </q-btn>
</template>
